//复制到剪切板
export const copyData = data => {
    let url = data
    let oInput = document.createElement('input');
    oInput.value = url;
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象;
    document.execCommand("Copy"); // 执行浏览器复制命令
}

//格式化数字，保留w位小数(默认两位)
export const fomatFloat = (num,w=2) => {
    let f = parseFloat(num);
    if (isNaN(f)) {
        return false
    }

    f = Math.round(num*Math.pow(10,w))/(Math.pow(10,w))
    let s = f.toString()
    let rs = s.indexOf('.')
    if(rs < 0) {
        rs = s.length
        s += '.'
    }
    while (s.length <= rs + w) {
        s += '0'
    }

    return s
}

//千位符
export const thousandBitSeparator = (num) => {
    let source = num.toString().split(".");
	source[0] = source[0].replace(new RegExp('(\\d)(?=(\\d{3})+$)','ig'),"$1,");
	return source.join(".");
}

//获取当前时间
export const now = () => {
    const n = new Date();
    const year = n.getFullYear();
    const month = (((n.getMonth()) + 1)+'').length === 1 ? ('0' + ((n.getMonth()) + 1)) : ((n.getMonth()) + 1);
    const date = (n.getDate()+'').length === 1 ? ('0' + n.getDate()) : n.getDate();
    const hour = (n.getHours()+'').length === 1 ? ('0' + n.getHours()) : n.getHours();
    const minute = (n.getMinutes()+'').length === 1 ? ('0' + n.getMinutes()) : n.getMinutes();
    const second = (n.getSeconds()+'').length === 1 ? ('0' + n.getSeconds()) : n.getSeconds();

    return year + '-' + month + '-' + date + ' ' + hour + ':' + minute + ':' + second
}

//风声计算经验等级
export const initLevelTable = () => {
    let _loc1_ = 0
    let levelTable = []
    _loc1_ = 1
    while(_loc1_ <= 100) {
        if(_loc1_ <= 20) {
            levelTable[_loc1_] = 2.5 * Math.pow(_loc1_,2) - 2.5 * _loc1_
        } else {
            levelTable[_loc1_] = Math.floor(Math.pow(2.5 * Math.pow(_loc1_,2) - 2.5 * _loc1_,1.05) - Math.pow(_loc1_,2))
        }
        _loc1_++
    }
    return levelTable
}

export const getLevelByExp = (exp) => {
    let _loc2_ = 0
    let levelTable = initLevelTable()
    _loc2_ = 1
    while(_loc2_ <= 100) {
        if(exp < levelTable[_loc2_]) {
            return (_loc2_ - 1).toString()
        }
        _loc2_++
    }
    return "100"
}
