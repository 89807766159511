<template>
    <el-dialog
        title="修改密码"
        :visible.sync="PwdDialogVisible"
        width="30%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => ClosePwdDialog('PassWordForm')"
    >
        <el-form
            :model="pwd"
            ref="PassWordForm"
            label-width="100px"
        >
            <el-descriptions
                class="margin-top"
                :column="1"
                :labelStyle="{'width':'100px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>旧密码</span>
                    </template>
                    <el-input v-model="pwd.old_password" show-password></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>新密码</span>
                    </template>
                    <el-input v-model="pwd.new_password" show-password></el-input>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>确认密码</span>
                    </template>
                    <el-input v-model="pwd.confirm_password" show-password></el-input>
                </el-descriptions-item>
            </el-descriptions>
        </el-form>
        <div slot="footer">
            <el-button type="primary" plain @click="SubmitPassword('PassWordForm')">确 定</el-button>
            <el-button plain @click="ClosePwdDialog('PassWordForm')">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { PassWordChangeRequest } from '@/network/user.js'

export default {
    name: 'PasswordDialogComponent',
    data() {
        return {
            pwd: {
                old_password: '',
                new_password: '',
                confirm_password: '',
            }
        }
    },
    computed: {},
    methods: {
        ClosePwdDialog(formName) {
            this.$refs[formName].resetFields()
            this.$emit('ExitPwdDialog')
        },
        SubmitPassword(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                if (this.pwd.old_password === '') {
                    this.$message.error('请填写旧密码')
                    return
                }

                if (this.pwd.new_password === '') {
                    this.$message.error('请填写新密码')
                    return
                }

                if (this.pwd.confirm_password === '') {
                    this.$message.error('请填写确认密码')
                    return
                }

                if (this.pwd.new_password !== this.pwd.confirm_password) {
                    this.$message.error('两次新密码不一致')
                    return
                }
                
                if (this.pwd.old_password == this.pwd.new_password) {
                    this.$message.error('旧密码与新密码相同')
                    return
                }

                PassWordChangeRequest(this.pwd)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('修改成功!')
                            this.ClosePwdDialog(formName)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        },
    },
    filters: {},
    props: {
        PwdDialogVisible: Boolean,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>