<template>
    <div>
        <el-dialog
            title="我的档案"
            :visible.sync="MyInfoDialogVisible"
            :append-to-body="true"
            width="800px"
            :center="true"
            top="100px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitMyInfoDialog')"
        >
            <el-descriptions
                :column="2"
                :labelStyle="{'width':'120px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>用户名</span>
                    </template>
                    <span>{{ data.username }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>昵称</span>
                    </template>
                    <span>{{ data.name }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>性别</span>
                    </template>
                    <span>{{ data.gender === 2 ? '女' : '男' }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>等级</span>
                    </template>
                    <span>{{ 'LV '+exp[0] }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>经验</span>
                    </template>
                    <span>{{ exp[1]+'/'+exp[2] }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>风币</span>
                    </template>
                    <span>{{ data.coin }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>对战场数</span>
                    </template>
                    <span>{{ data.duleCount }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>胜率</span>
                    </template>
                    <span>{{ shenglv }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>最高连胜</span>
                    </template>
                    <span>{{ data.streak }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>军情获胜场数</span>
                    </template>
                    <span>{{ winCount[0] }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>杀人数</span>
                    </template>
                    <span>{{ data.killCount }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>潜伏获胜场数</span>
                    </template>
                    <span>{{ winCount[1] }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>死亡次数</span>
                    </template>
                    <span>{{ data.deathCount }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>酱油获胜场数</span>
                    </template>
                    <span>{{ winCount[2] }}</span>
                </el-descriptions-item>
            </el-descriptions>
            <div slot="footer">
                <el-button plain @click="$emit('ExitMyInfoDialog')">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { fomatFloat,getLevelByExp,initLevelTable } from '@/assets/js/common.js'

export default {
    name:'',
    data(){
        return {}
    },
    computed:{
        shenglv() {
            if(this.data.winCount) {
                const arr = this.data.winCount.split(",")
                let sum = arr[0]*1 + arr[1]*1 + arr[2]*1
                return sum === 0 ? 0 : fomatFloat(sum/this.data.duleCount*100,2)+'%'
            }
            return ''
        },
        exp() {
            if(this.data.exp) {
                const rank = getLevelByExp(this.data.exp) *1    //当前等级
                const rankExpTable = initLevelTable()   //所有等级所需经验数组
                const rank_exp = rankExpTable[rank]   //当前等级所需总经验
                const rank_shengyu_exp = this.data.exp*1 - rank_exp //当前等级剩余经验
                const next_rank_exp = rankExpTable[rank*1+1]  //下一级所需总经验
                const next_rank_xuyao_exp = next_rank_exp - rank_exp    //升级下一级需要的总经验（等级差经验）
                if(rank*1 === 100) {
                    return [100,0,0]
                }
                return [rank,rank_shengyu_exp,next_rank_xuyao_exp]
            }

            return ['','','']
        },
        winCount() {
            if(this.data.winCount) {
                return this.data.winCount.split(',')
            }

            return ['','','']
        }
    },
    methods:{},
    filters:{},
    props:{
        MyInfoDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>