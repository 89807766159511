<template>
    <el-dialog
        title="修改个性签名"
        :visible.sync="MakerDialogVisible"
        width="50%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => CloseMakerDialog('MakerForm')"
    >
        <el-form
            :model="Maker"
            ref="MakerForm"
            label-width="100px"
        >
            <el-descriptions
                class="margin-top"
                :column="1"
                :labelStyle="{'width':'100px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                border
            >
                <el-descriptions-item :contentStyle="{'font-size':'18px','text-align': 'center','color':'#9F35FF','font-weight':'bold','white-space':'pre-line'}">
                    <template slot="label">
                        <span>原签名</span>
                    </template>
                    <span>{{ OldMaker }}</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>新签名</span>
                    </template>
                    <el-input
                        type="textarea"
                        :rows="3"
                        resize="none"
                        :maxlength="30"
                        show-word-limit
                        v-model="Maker.maker"
                        clearable
                        class="maker_c"
                    ></el-input>
                </el-descriptions-item>
            </el-descriptions>
        </el-form>
        <div slot="footer">
            <el-button type="primary" plain @click="SubmitMaker('MakerForm')">确 定</el-button>
            <el-button plain @click="CloseMakerDialog('MakerForm')">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { MakerChangeRequest } from '@/network/user.js'

export default {
    name: 'MakerDialogComponent',
    data() {
        return {
            Maker: {
                maker: ''
            }
        }
    },
    computed: {},
    methods: {
        CloseMakerDialog(formName) {
            this.Maker = {
                maker: ''
            }
            this.$emit('ExitMakerDialog')
        },
        SubmitMaker(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                if (this.Maker.maker === '') {
                    this.$message.error('请填写新签名')
                    return
                }
                
                if (this.Maker.maker == this.OldMaker) {
                    this.$message.error('新签名与原签名一致')
                    return
                }

                MakerChangeRequest(this.Maker)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$emit('EditMaker',this.Maker.maker)    //修改主页的签名显示
                            this.$message.success('修改成功!')
                            this.CloseMakerDialog(formName)
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        },
    },
    filters: {},
    props: {
        MakerDialogVisible: Boolean,
        OldMaker: {
            type: String,
            default() {
                return ''
            }
        }
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>