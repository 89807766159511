<template>
    <el-dialog
        title="物品分解"
        :visible.sync="KillDialogVisible"
        width="400px"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => CloseKillDialog()"
        v-loading.fullscreen.lock="login_loading"
    >
        <el-descriptions
            class="margin-top"
            :column="1"
            :labelStyle="{'width':'120px','text-align':'center'}"
            :contentStyle="{'text-align': 'center'}"
            border
        >
            <el-descriptions-item>
                <template slot="label">
                    <span>分解物品</span>
                </template>
                <img :src="KillDate.url" height="100px" alt="" style="border-radius: 10px;" v-if="KillDate.url">
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>分解数量</span>
                </template>
                <el-input-number
                    v-model="KillNum"
                    :min="1"
                    :max="KillDate.max_num"
                    :step-strictly="true"
                    class="input_num"
                ></el-input-number>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>可获得积分</span>
                </template>
                <span style="font-family: HarmonyOS_Sans_Black;font-size: 20px;color: #00BB00;">{{ KillDate.kill_score*KillNum }}</span>
            </el-descriptions-item>
            <el-descriptions-item>
                <template slot="label">
                    <span>当前拥有积分</span>
                </template>
                <span style="font-family: HarmonyOS_Sans_Black;font-size: 20px;color: #CE0000;">{{ KillDate.user_score }}</span>
            </el-descriptions-item>
        </el-descriptions>
        <div slot="footer">
            <el-button type="primary" plain @click="SubmitKill()">确 定</el-button>
            <el-button plain @click="CloseKillDialog()">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
import { ShopArticleKillGoRequest } from '@/network/shop/ShopArticle.js'

export default {
    name: 'KillDialogComponent',
    data() {
        return {
            KillNum: 1,
            login_loading: false
        }
    },
    computed: {},
    methods: {
        CloseKillDialog() {
            this.KillNum = 1
            this.$emit('ExitKillDialog')
        },
        SubmitKill() {
            this.login_loading = true
            const res = {
                name: this.KillDate.name,
                num: this.KillNum
            }
            ShopArticleKillGoRequest(res)
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('分解成功!')

                        this.KillNum = 1
                        this.$emit('ExitKillDialog')
                        this.$emit('Refresh')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.login_loading = false)
        }
    },
    filters: {},
    props: {
        KillDialogVisible: Boolean,
        KillDate: Object
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'>
.el-input-number {
    width: 100%;
}
</style>