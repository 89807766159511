<template>
    <div>
        <el-dialog
            title="我的奖券"
            :visible.sync="MyCdKeyDialogVisible"
            :append-to-body="true"
            width="800px"
            :center="true"
            top="100px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitMyCdKeyDialog')"
        >
            <el-table
                :data="data"
                style="width: 100%"
                border
                stripe
                highlight-current-row
                size="mini"
                height="405px"
            >
                <el-table-column>
                    <template v-slot:header>
                        <div style="text-align: center;color: #2828FF;font-family: HarmonyOS_Sans_Black;font-size: 15px;">
                            <span>未使用的奖券</span>
                        </div>
                    </template>
                    <el-table-column
                        align="center"
                        type="index"
                        min-width="20px">
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="key"
                        label="奖券KEY"
                        min-width="200px"
                    >
                        <template slot-scope="scope">
                            <span>{{ scope.row.key }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                        align="center"
                        prop="name"
                        label="绑定玩家"
                        min-width="180px"
                    >
                        <template slot-scope="scope">
                            <span style="color: #CE0000;">{{ scope.row.name }}</span>
                        </template>
                    </el-table-column>
                </el-table-column>
            </el-table>
            <prize-draw-component
                :dialogVisible="PrizeDialogVisible"
                @RefreshBindKey="$emit('RefreshBindKey')"
                @closeDialog="PrizeDialogVisible = false"
            ></prize-draw-component>
            <common-prize-draw-component
                :CommonPrizeDialogVisible="CommonPrizeDialogVisible"
                @RefreshBindKey="$emit('RefreshBindKey')"
                @closeDialog="CommonPrizeDialogVisible = false"
            ></common-prize-draw-component>
            <chance-dialog-component
                :ChanceDialogVisible="ChanceDialogVisible"
                @ExitChanceDialog="ChanceDialogVisible = false"
            ></chance-dialog-component>
            <div slot="footer">
                <el-button type="primary" plain @click="ChanceDialogVisible = true">概率公示</el-button>
                <el-button type="success" plain @click="PrizeDialogVisible = true" v-if="data.length">去抽奖</el-button>
                <el-button type="warning" plain @click="CommonPrizeDialogVisible = true">手动兑换</el-button>
                <el-button plain @click="$emit('ExitMyCdKeyDialog')">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import PrizeDrawComponent from '@/components/index/PrizeDrawComponent'
import CommonPrizeDrawComponent from '@/components/index/CommonPrizeDrawComponent'
import ChanceDialogComponent from '@/components/index/ChanceDialogComponent'

export default {
    name:'',
    data(){
        return {
            ChanceDialogVisible: false,
            PrizeDialogVisible:false,
            CommonPrizeDialogVisible:false
        }
    },
    computed:{},
    methods:{},
    filters:{},
    props:{
        MyCdKeyDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        ChanceDialogComponent,
        PrizeDrawComponent,
        CommonPrizeDrawComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
</style>