import request from './request'

//用户注册
export const UserRegisterRequest = data => {
    return request({
        method:'POST',
        url:'UserRegister',
        data
    })
}

//用户登录
export const UserLoginRequest = data => {
    return request({
        method:'POST',
        url:'UserLogin',
        data
    })
}

//获取菜单
export const MenuRequest = () => {
    return request({
        method:'GET',
        url:'UserMenu'
    })
}

//获取用户拥有的权限
export const AuthList = () => {
    return request({
        method:'GET',
        url:'AuthList'
    })
}

//用户列表
export const UserIndexRequest = p => {
    return request({
        method:'GET',
        url:'User/index',
        params: p
    })
}

//获取用户详情
export const UserDetailsRequest = id => {
    return request({
        method:'GET',
        url:'User/details',
        params: {
            id
        }
    })
}

//删除用户
export const UserDelRequest = id => {
    return request({
        method:'DELETE',
        url:'User/del',
        data: {
            id
        }
    })
}

//同意注册
export const UserPassRegisterRequest = data => {
    return request({
        method:'PUT',
        url:'User/PassRegister',
        data
    })
}
//用户授权
export const UserEmpowerRequest = data => {
    return request({
        method:'PUT',
        url:'User/empower',
        data
    })
}

//获取个人信息
export const MyInformationRequest = () => {
    return request({
        method:'GET',
        url:'GetInformation'
    })
}

//用户注销
export const UserLogOffRequest = user_id => {
    return request({
        method:'DELETE',
        url:'User/LogOff',
        data: {
            user_id
        }
    })
}

//发放积分
export const UserGiveScoreRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveScore',
        data
    })
}

//发放金币
export const UserGiveCoinRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveCoin',
        data
    })
}

//发放奖券
export const UserGiveKeyRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveKey',
        data
    })
}

//发放物品
export const UserGiveItemRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveItem',
        data
    })
}

//发放静态皮肤
export const UserGiveSkinRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveSkin',
        data
    })
}

//发放动态皮肤
export const UserGiveDtSkinRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveDtSkin',
        data
    })
}

//发放边框
export const UserGiveFrameRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveFrame',
        data
    })
}

//发放背景墙
export const UserGiveBackRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveBack',
        data
    })
}

//发放昵称框
export const UserGiveNickRequest = data => {
    return request({
        method:'POST',
        url:'User/GiveNick',
        data
    })
}

//修改个人密码
export const PassWordChangeRequest = data => {
    return request({
        method:'PUT',
        url:'PassWordChange',
        data
    })
}

//个性签名
export const MakerChangeRequest = data => {
    return request({
        method:'PUT',
        url:'MakerChange',
        data
    })
}

//获取个人物品
export const UserItemsRequest = () => {
    return request({
        method:'GET',
        url:'UserItems'
    })
}

//获取个人CDKey
export const UserBindKeyRequest = () => {
    return request({
        method:'GET',
        url:'UserBindKey'
    })
}

//兑换皮肤
export const ExchangeSkinRequest = data => {
    return request({
        method:'PUT',
        url:'ExchangeSkin',
        data
    })
}

//兑换边框
export const ExchangeFrameRequest = data => {
    return request({
        method:'PUT',
        url:'ExchangeFrame',
        data
    })
}

//兑换动态皮肤
export const ExchangeSkinDtRequest = data => {
    return request({
        method:'PUT',
        url:'ExchangeSkinDt',
        data
    })
}

//兑换昵称框
export const ExchangeNickRequest = data => {
    return request({
        method:'PUT',
        url:'ExchangeNick',
        data
    })
}

//兑换背景
export const ExchangeBackRequest = data => {
    return request({
        method:'PUT',
        url:'ExchangeBack',
        data
    })
}

//积分兑换
export const ScoreExchangeRequest = data => {
    return request({
        method:'POST',
        url:'ScoreExchange',
        data
    })
}

//奖券抽奖
export const KeyUseRequest = data => {
    return request({
        method:'POST',
        url:'User/KeyUse',
        data
    })
}

//通用奖券抽奖
export const CommonKeyUseRequest = data => {
    return request({
        method:'POST',
        url:'User/CommonKeyUse',
        data
    })
}

//获取个人周报
export const UserWeeklyRequest = p => {
    return request({
        method:'GET',
        url:'UserWeekly',
        params: p
    })
}

//获取个人年报
export const UserYearlyRequest = () => {
    return request({
        method:'GET',
        url:'UserYearly'
    })
}

//获取个人年报玩家
export const UserYearlyPartnerRequest = () => {
    return request({
        method:'GET',
        url:'UserYearlyPartner'
    })
}

//获取奖品列表
export const ScoreArticleRequest = () => {
    return request({
        method:'GET',
        url:'ScoreArticle'
    })
}

//设置默认皮肤或装扮
export const EditDefaultRequest = data => {
    return request({
        method:'POST',
        url:'EditDefault',
        data
    })
}