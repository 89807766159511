<template>
    <div>
        <el-dialog
            title="我的物品"
            :visible.sync="MyItemDialogVisible"
            :append-to-body="true"
            width="800px"
            :center="true"
            top="100px"
            :close-on-click-modal="false"
            :before-close="() => ExitDialog()"
        >
            <div style="height:485px;overflow-y:auto">
                <div style="font-family: HarmonyOS_Sans_Light;font-weight: bold;font-size: 20px;text-align: center;margin-bottom: 10px;">
                    <span style="color: #B15BFF;">当前拥有荣誉积分：</span><span style="font-family: HarmonyOS_Sans_Black;color: #B15BFF;margin-right: 10px;">{{ TotalScore }}</span>
                </div>
                <el-descriptions
                    direction="vertical"
                    :column="3"
                    :labelStyle="{'text-align':'center','background-color':'#FFFFFF','width':'33.3%','height':'130px'}"
                    :contentStyle="{'text-align': 'center','background-color':'#ECF5FF','height':'67px'}"
                    border
                >
                    <el-descriptions-item v-for="item in data" :key="item.name">
                        <template slot="label">
                            <img :src="item.url" height="100px" alt="" style="border-radius: 10px;">
                        </template> 
                        <div style="font-family: HarmonyOS_Sans_Black;font-size: 14px;">
                            <div style="color:#2828FF;">{{ item.name }}</div>
                            <div><span>数量：</span><span style="color: #CE0000;">{{ item.num }}</span></div>
                            <div><el-button plain size="mini" @click="kill(item)">分解</el-button></div>
                        </div>
                    </el-descriptions-item>
                    <el-descriptions-item v-for="i in res_buchong" :key="i+'bc'">
                        <template slot="label">
                            <span></span>
                        </template>
                        <div style="width: 100%"></div>
                    </el-descriptions-item>
                </el-descriptions>
            </div>
            <div slot="footer">
                <el-button plain @click="ExitDialog">关闭</el-button>
            </div>

            <kill-dialog-component
                :KillDialogVisible="KillDialogVisible"
                :KillDate="kill_details"
                @ExitKillDialog="KillDialogVisible = false"
                @Refresh="$emit('Refresh')"
            ></kill-dialog-component>
        </el-dialog>
    </div>
</template>

<script>
import { ShopArticleKillDetailsRequest } from '@/network/shop/ShopArticle.js'

import KillDialogComponent from '@/components/index/KillDialogComponent'

export default {
    name:'',
    data(){
        return {
            KillDialogVisible: false,
            res_buchong: 0,
            kill_details: {
                name: '',
                url: '',
                score: '',
                user_name: '',
                user_score: '',
                kill_score: 0,
                max_num: 1
            }
        }
    },
    computed:{},
    methods:{
        ExitDialog() {
            this.res_buchong = 0,
            this.kill_details = {
                name: '',
                url: '',
                score: '',
                user_name: '',
                user_score: '',
                kill_score: 0,
                max_num: 1
            }
            this.$emit('ExitMyItemDialog')
        },
        kill(item) {
            ShopArticleKillDetailsRequest(item.name)
                .then((s) => {
                    if (s.status === 0) {
                        this.kill_details = s.result
                        this.kill_details.url = item.url
                        this.kill_details.max_num = item.num
                        this.KillDialogVisible = true
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters:{},
    props:{
        MyItemDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        TotalScore: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        KillDialogComponent
    },
    watch:{
        data(newVal) {
            if(newVal.length  < 7)
            {
                this.res_buchong = 6 - newVal.length;
            }
            else
            {
                const yushu = newVal.length % 6 === 0 ? 6 : newVal.length % 6
                this.res_buchong = 6 - yushu
            }
        }
    }
}
</script>

<style lang='less' scoped>
</style>