import request from '../request'

//积分记录
export const UserScoreIndexRequest = p => {
    return request({
        method:'GET',
        url:'UserScore/index',
        params: p
    })
}

//积分排行榜
export const UserScoreListRequest = p => {
    return request({
        method:'GET',
        url:'UserScore/ScoreList',
        params: p
    })
}

//竞技排行榜
export const UserSplListRequest = p => {
    return request({
        method:'GET',
        url:'UserScore/SplList',
        params: p
    })
}

//活跃排行榜
export const UserActiveListRequest = p => {
    return request({
        method:'GET',
        url:'UserScore/ActiveList',
        params: p
    })
}