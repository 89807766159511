import request from '../request'

//spl赛事查询
export const SplTodayRequest = () => {
    return request({
        method:'GET',
        url:'spl/today'
    })
}

//spl赛事报名
export const SplAddRequest = () => {
    return request({
        method:'POST',
        url:'spl/add'
    })
}