import request from '../request'

//荣誉历史
export const UserTaskIndexRequest = p => {
    return request({
        method:'GET',
        url:'UserTask/index',
        params: p
    })
}

//常规任务查询
export const UserTaskQueryRequest = p => {
    return request({
        method:'GET',
        url:'UserTask/query',
        params: p
    })
}

//等级任务查询
export const RankTaskQueryRequest = p => {
    return request({
        method:'GET',
        url:'TaskRank/index',
        params: p
    })
}

//等级任务领奖
export const RankTaskGainRequest = rank => {
    return request({
        method:'put',
        url:'TaskRank/gain',
        data: {
            rank: rank
        }
    })
}

//任务计算
export const UserTaskCountRequest = with_player_id => {
    return request({
        method:'POST',
        url:'UserTask/count',
        data: {
            users_id: with_player_id
        }
    })
}

//任务刷新
export const UserTaskRefreshRequest = user_task_id => {
    return request({
        method:'PUT',
        url:'UserTask/refresh',
        data: {
            user_task_id: user_task_id
        }
    })
}