import request from '../request'

//物品列表
export const ShopArticleIndexRequest = p => {
    return request({
        method:'GET',
        url:'ShopArticle/index',
        params: p
    })
}

//添加物品
export const ShopArticleAddRequest = d => {
    return request({
        method:'POST',
        url:'ShopArticle/add',
        data: d
    })
}

//删除物品
export const ShopArticleDelRequest = id => {
    return request({
        method:'DELETE',
        url:'ShopArticle/del',
        data: {
            id: id
        }
    })
}

//修改物品
export const ShopArticleEditRequest = data => {
    return request({
        method:'PUT',
        url:'ShopArticle/edit',
        data
    })
}

//获取物品详情
export const ShopArticleDetailsRequest = id => {
    return request({
        method:'GET',
        url:'ShopArticle/details',
        params: {
            id
        }
    })
}

//获取分解物品详情
export const ShopArticleKillDetailsRequest = name => {
    return request({
        method:'GET',
        url:'ShopArticle/kill_details',
        params: {
            name
        }
    })
}

//分解物品
export const ShopArticleKillGoRequest = data => {
    return request({
        method:'POST',
        url:'ShopArticle/kill_go',
        data
    })
}