<template>
    <div>
        <el-dialog
            :visible.sync="dialogVisible"
            :append-to-body="true"
            width="1000px"
            :center="true"
            top="50px"
            :fullscreen="true"
            :close-on-click-modal="false"
            :show-close="false"
            :before-close="() => closeDialog()"
            id="PrizeDialog"
        >
            <div class="the-card">
                <div class="wrap">
                    <div class="card cover"
                        v-for="item in cards" :key="item.id"
                        :class="{
                            'cardHover': !gameOver,
                            'blur': item.id != hoverId && beginHover,
                            'turned': item.isTurned,
                            'gameOver': !item.isTurned && gameOver,
                        }" 
                        @mouseenter="hoverItem(item)" 
                        @mouseout="reset()"
                        @click="key_use(item)"
                    >
                        <div :class="{'back':back}" v-if="back">
                            <img :src="item.url" style="position:absolute;text-align: center;top:20px;width:80%;border-radius: 10px;">
                            <span style="position:absolute;text-align: center;top:140px;font-family: HarmonyOS_Sans_Black;font-weight: bold;font-size: 20px;color: #EA0000;width:90%;">{{ item.content }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div style="text-align: center;">
                <el-button v-if="gameOver" plain @click="closeDialog()">关闭</el-button>
                <el-button v-if="!gameOver" plain @click="closeDialog()">取消抽奖</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { KeyUseRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            cards: [
                {id:1,content:'你才是兔子',isTurned:false},
                {id:2,content:'你才是兔子',isTurned:false},
                {id:3,content:'你才是兔子',isTurned:false},
                {id:4,content:'你才是兔子',isTurned:false},
                {id:5,content:'你才是兔子',isTurned:false}
            ],
            hoverId: -1,
            hoverJiang: '',
            beginHover: false,
            gameOver: false,
            back: false
        }
    },
    computed:{},
    methods:{
        hoverItem(item){
            if(!this.gameOver){
                this.hoverId = item.id
                this.beginHover = true
            }
        },
        reset(){
            this.beginHover = false
        },
        turnItem(item){
            if(this.gameOver) return
            this.cards.map(card => {
                card.id === item.id ? card.isTurned = true : "";
            })

            setTimeout(() => {
                this.beginHover = false
                this.gameOver = true
            }, 300);

            setTimeout(() => {
                this.back = true
            }, 500);
        },
        closeDialog() {
            this.cards.map(v=>v.isTurned=false)
            this.hoverId = -1,
            this.hoverJiang =  '',
            this.beginHover = false,
            this.gameOver = false,
            this.back = false
            this.$emit('closeDialog')
        },
        key_use(item) {
            if(this.gameOver === true) {
                return
            }
            const obj = item
            KeyUseRequest({id:obj.id})
                .then((s) => {
                    if (s.status === 0) {
                        this.cards = s.result.cards
                        this.hoverJiang = s.result.item
                        this.turnItem(obj)  //翻转动画
                        this.$emit('RefreshBindKey')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        }
    },
    filters:{},
    props:{
        dialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>
<style>
#PrizeDialog>.el-dialog {
    background-color:transparent;
}
</style>
<style lang="less" scoped>
    .the-card {
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;

        .wrap {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            
            .card {
                width: 167px;
                height: 234px;
                margin: 0 1rem;
                transition: 1s;
                cursor: pointer;
                border-radius: 10px;
                position: relative;
            }

            // 福卡封面
            .cover {
                background: url(../../assets/img/choujiangka.png) center center no-repeat;
                background-size: 100% 100%; // 用contain会出现图片大小变化div外层不变
            }  

            // 没有被hover的卡片变模糊
            .blur {
                filter: blur(25px);
                transform: scale(.6); 
            }

            // 卡片点击翻转
            .turned {
                box-shadow: 0px 0px 20px 10px #f1c40f;
                transform: perspective(900px) rotateY(180deg) scale(1) translateY(-30%);
            }

            // hover卡片放大，游戏结束后失效
            .cardHover:not(.turned):hover {
                transform: scale(1.3);
                filter: none;
            }

            .back {
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                background: url(../../assets/img/jiangpinbiankuang.png) center center no-repeat;
                background-size: 100% 100%; // 用contain会出现图片大小变化div外层不变
                transform: rotateY(180deg);
                font-size: 1rem;
                color: #f1c40f;
                border-radius: 10px;
                display: flex;
                justify-content: center;
                align-items: center;
            }   
            
            // 游戏结束，未翻开的牌自动翻开向下移动
            .gameOver {
                transform: perspective(900px) rotateY(180deg) scale(1);
                cursor: default;
            }
        }
    }
</style>