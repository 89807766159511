<template>
    <div>
        <el-dialog
            title="情报局"
            :visible.sync="ReportDialogVisible"
            :append-to-body="true"
            width="900px"
            :center="true"
            top="10px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitReportDialog')"
        >
            <div style="margin-bottom: 5px;text-align: center;">
                <el-radio-group v-model="type" size="small">
                    <el-radio-button label="week">上周周报</el-radio-button>
                    <el-radio-button label="month">上月月报</el-radio-button>
                </el-radio-group>
            </div>
            <el-descriptions
                :column="1"
                :labelStyle="{'width':'110px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                size="small"
                border
                v-if="type === 'week'"
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>最<span style="font-weight: bold;font-size: 16px;color: #EA0000;">强</span>角色</span>
                    </template>
                    <span>【</span>
                    <span style="font-weight: bold;font-size: 16px;color: #EA0000;">{{ WeeklyData.role_win_arr.role }}</span>
                    <span>】上周</span>
                    <span style="font-weight: bold;color: #EA0000;">胜场最多</span>
                    <span>，所有玩家合计胜利</span>
                    <span style="font-weight: bold;font-size: 16px;color: #EA0000;">{{ WeeklyData.role_win_arr.num }}</span>
                    <span>场</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>最<span style="font-weight: bold;font-size: 16px;color: #000000;">惨</span>角色</span>
                    </template>
                    <span>【</span>
                    <span style="font-weight: bold;font-size: 16px;color: #000000;">{{ WeeklyData.role_lose_arr.role }}</span>
                    <span>】上周</span>
                    <span style="font-weight: bold;color: #000000;">败场最多</span>
                    <span>，所有玩家合计失败</span>
                    <span style="font-weight: bold;font-size: 16px;color: #000000;">{{ WeeklyData.role_lose_arr.num }}</span>
                    <span>场</span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>我的对局</span>
                    </template>
                    <div v-if="WeeklyData.my_all_num">
                        <span>上周对局</span>
                        <span style="font-weight: bold;font-size: 16px;">{{ WeeklyData.my_all_num }}</span>
                        <span>场，胜利</span>
                        <span style="font-weight: bold;font-size: 16px;color: #EA0000;">{{ WeeklyData.my_win_num }}</span>
                        <span>场，胜率</span>
                        <span style="font-weight: bold;font-size: 16px;">{{ WeeklyData.my_win_ratio }}</span>
                    </div>
                    <div v-else style="font-weight: bold;font-size: 16px;">上周未参加对局</div>
                    
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>我的<span style="font-weight: bold;font-size: 16px;color: #8600FF;">幸运</span>角色</span>
                    </template>
                    <div v-if="WeeklyData.my_lucky_role_arr.num">
                        <span>【</span>
                        <span style="font-weight: bold;font-size: 16px;color: #8600FF;">{{ WeeklyData.my_lucky_role_arr.role }}</span>
                        <span>】为我上周</span>
                        <span style="font-weight: bold;color: #EA0000;">胜场最多</span>
                        <span>的角色，胜利</span>
                        <span style="font-weight: bold;font-size: 16px;color: #8600FF;">{{ WeeklyData.my_lucky_role_arr.num }}</span>
                        <span>场</span>
                    </div>
                    <div v-else style="font-weight: bold;font-size: 16px;">上周未参加对局</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>我的<span style="font-weight: bold;font-size: 16px;color: #EA0000;">最佳</span>搭档</span>
                    </template>
                    <div v-if="WeeklyData.my_win_partner.num">
                        <span>【</span>
                        <span style="font-weight: bold;font-size: 16px;color: #EA0000;">{{ WeeklyData.my_win_partner.userNickName }}</span>
                        <span>】为上周与我共同</span>
                        <span style="font-weight: bold;color: #EA0000;">胜利最多</span>
                        <span>的玩家，共同胜利</span>
                        <span style="font-weight: bold;font-size: 16px;color: #EA0000;">{{ WeeklyData.my_win_partner.num }}</span>
                        <span>场</span>
                    </div>
                    <div v-else style="font-weight: bold;font-size: 16px;">上周未参加对局</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>我的<span style="font-weight: bold;font-size: 16px;color: #000000;">最坑</span>搭档</span>
                    </template>
                    <div v-if="WeeklyData.my_lose_partner.num">
                        <span>【</span>
                        <span style="font-weight: bold;font-size: 16px;color: #000000;">{{ WeeklyData.my_lose_partner.userNickName }}</span>
                        <span>】为上周与我共同</span>
                        <span style="font-weight: bold;color: #000000;">失败最多</span>
                        <span>的玩家，共同失败</span>
                        <span style="font-weight: bold;font-size: 16px;color: #000000;">{{ WeeklyData.my_lose_partner.num }}</span>
                        <span>场</span>
                    </div>
                    <div v-else style="font-weight: bold;font-size: 16px;">上周未参加对局</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>我的<span style="font-weight: bold;font-size: 16px;color: #64A600;">下酒菜</span></span>
                    </template>
                    <div v-if="WeeklyData.my_xiajiucai_partner.num">
                        <span>【</span>
                        <span style="font-weight: bold;font-size: 16px;color: #64A600;">{{ WeeklyData.my_xiajiucai_partner.userNickName }}</span>
                        <span>】上周与我共同对局，</span>
                        <span style="font-weight: bold;color: #EA0000;">我胜</span>
                        <span style="font-weight: bold;color: #000000;">ta败</span>
                        <span>场数最多，为</span>
                        <span style="font-weight: bold;font-size: 16px;color: #64A600;">{{ WeeklyData.my_xiajiucai_partner.num }}</span>
                        <span>场</span>
                    </div>
                    <div v-else style="font-weight: bold;font-size: 16px;">上周未参加对局</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>我的<span style="font-weight: bold;font-size: 16px;color: #AE57A4;">克星</span></span>
                    </template>
                    <div v-if="WeeklyData.my_kexing_partner.num">
                        <span>【</span>
                        <span style="font-weight: bold;font-size: 16px;color: #AE57A4;">{{ WeeklyData.my_kexing_partner.userNickName }}</span>
                        <span>】上周与我共同对局，</span>
                        <span style="font-weight: bold;color: #EA0000;">ta胜</span>
                        <span style="font-weight: bold;color: #000000;">我败</span>
                        <span>场数最多，为</span>
                        <span style="font-weight: bold;font-size: 16px;color: #AE57A4;">{{ WeeklyData.my_kexing_partner.num }}</span>
                        <span>场</span>
                    </div>
                    <div v-else style="font-weight: bold;font-size: 16px;">上周未参加对局</div>
                </el-descriptions-item>
            </el-descriptions>
            <el-descriptions
                :column="1"
                :labelStyle="{'width':'110px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                size="small"
                border
                v-else-if="type === 'month'"
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>上月战绩</span>
                    </template>
                    <span>你上月共进行<span style="font-size: 16px;color: #2828FF;">{{ YearlyData.count_play_num }}</span>场对局,</span>
                    <span>获胜<span style="font-size: 16px;color: #2828FF;">{{ YearlyData.count_play_win_num }}</span>场,</span>
                    <span>胜率竟然高达<span style="font-size: 16px;color: #2828FF;">{{ YearlyData.count_play_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span><span style="font-size: 16px;color: #7D7DFF;">身份</span>模式</span>
                    </template>
                    <span>你上月共参与<span style="font-weight: bold;color: #7D7DFF;">身份</span>模式<span style="font-size: 16px;color: #7D7DFF;">{{ YearlyData.count_play_shenfen_num }}</span>场，</span>
                    <span>胜利<span style="font-size: 16px;color: #7D7DFF;">{{ YearlyData.count_play_shenfen_win_num }}</span>场，胜率<span style="font-size: 16px;color: #7D7DFF;">{{ YearlyData.count_play_shenfen_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span><span style="font-size: 16px;color: #BB5E00;">3V3</span>模式</span>
                    </template>
                    <span>你上月共参与<span style="font-weight:bold;color: #BB5E00;">3V3</span>模式<span style="font-size: 16px;color: #BB5E00;">{{ YearlyData.count_play_sansan_num }}</span>场，</span>
                    <span>胜利<span style="font-size: 16px;color: #BB5E00;">{{ YearlyData.count_play_sansan_win_num }}</span>场，胜率<span style="font-size: 16px;color: #BB5E00;">{{ YearlyData.count_play_sansan_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span><span style="font-size: 16px;color: #00BB00;">酱油</span>模式</span>
                    </template>
                    <span>你上月共参与<span style="font-weight: bold;color: #00BB00;">酱油</span>模式<span style="font-size: 16px;color: #00BB00;">{{ YearlyData.count_play_jiangyou_num }}</span>场，</span>
                    <span>胜利<span style="font-size: 16px;color: #00BB00;">{{ YearlyData.count_play_jiangyou_win_num }}</span>场，胜率<span style="font-size: 16px;color: #00BB00;">{{ YearlyData.count_play_jiangyou_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span><span style="font-size: 16px;color: #0080FF;">军情</span>身份</span>
                    </template>
                    <span>你上月共获得<span style="font-weight: bold;color: #0080FF;">军情</span>身份<span style="font-size: 16px;color: #0080FF;">{{ YearlyData.count_play_jun_num }}</span>场，</span>
                    <span>胜利<span style="font-size: 16px;color: #0080FF;">{{ YearlyData.count_play_jun_win_num }}</span>场，胜率<span style="font-size: 16px;color: #0080FF;">{{ YearlyData.count_play_jun_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span><span style="font-size: 16px;color: #CE0000;">潜伏</span>身份</span>
                    </template>
                    <span>你上月共获得<span style="font-weight:bold;color: #CE0000;">潜伏</span>身份<span style="font-size: 16px;color: #CE0000;">{{ YearlyData.count_play_qian_num }}</span>场，</span>
                    <span>胜利<span style="font-size: 16px;color: #CE0000;">{{ YearlyData.count_play_qian_win_num }}</span>场，胜率<span style="font-size: 16px;color: #CE0000;">{{ YearlyData.count_play_qian_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span><span style="font-size: 16px;color: #00BB00;">酱油</span>身份</span>
                    </template>
                    <span>你上月共获得<span style="font-weight: bold;color: #00BB00;">酱油</span>身份<span style="font-size: 16px;color: #00BB00;">{{ YearlyData.count_play_jiang_num }}</span>场，</span>
                    <span>胜利<span style="font-size: 16px;color: #00BB00;">{{ YearlyData.count_play_jiang_win_num }}</span>场，胜率<span style="font-size: 16px;color: #00BB00;">{{ YearlyData.count_play_jiang_win_ratio }}%</span></span>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>常用<span style="font-size: 16px;">角色</span></span>
                    </template>
                    <el-row :gutter="20">
                        <el-col :span="8" v-for="item in YearlyData.my_max_role_arr" :key="item.role_name">
                            <el-col :span="10">
                                <div style="text-align: center;"><img :src="item.role_picture" height="120px" alt="" style="border-radius: 10px;"></div>
                            </el-col>
                            <el-col :span="14">
                                <div style="text-align: left;margin-top: 30px;">
                                    <img v-if="item.role_gender === 1" :src="$store.state.baseURL+'icon/xingbienan.png'" height="14px"><span v-if="item.role_gender === 1" style="color: #2894FF;">{{ item.role_name }}</span>
                                    <img v-if="item.role_gender === 2" :src="$store.state.baseURL+'icon/xingbienv.png'" height="14px"><span v-if="item.role_gender === 2" style="color: #FF60AF;">{{ item.role_name }}</span>
                                </div>
                                <div style="text-align: left;">总场数<span style="color: #2828FF;font-weight: bold;font-size: 16px;">{{ item.num }}</span>场</div>
                                <div style="text-align: left;">胜率<span style="color: #8600FF;font-weight: bold;font-size: 16px;">{{ item.win_ratio }}%</span></div>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>常玩<span style="font-size: 16px;">伙伴</span></span>
                    </template>
                    <el-row :gutter="20">
                        <el-col :span="8" v-for="item in YearlyPartner" :key="item.name">
                            <el-col :span="10">
                                <div style="text-align: center;"><img :src="item.picture" height="120px" alt="" style="border-radius: 10px;"></div>
                            </el-col>
                            <el-col :span="14">
                                <div style="text-align: left;margin-top: 20px;">
                                    <img v-if="item.gender === 1" :src="$store.state.baseURL+'icon/xingbienan.png'" height="14px"><span v-if="item.gender === 1" style="color: #2894FF;">{{ item.name }}</span>
                                    <img v-if="item.gender === 2" :src="$store.state.baseURL+'icon/xingbienv.png'" height="14px"><span v-if="item.gender === 2" style="color: #FF60AF;">{{ item.name }}</span>
                                </div>
                                <div style="text-align: left;">共同对局<span style="color: #2828FF;font-weight: bold;font-size: 16px;">{{ item.num }}</span>场</div>
                                <div style="text-align: left;">共同胜利<span style="color: #CE0000;font-weight: bold;font-size: 16px;">{{ item.all_win_num }}</span>场</div>
                                <div style="text-align: left;">共同失败<span style="color: #000000;font-weight: bold;font-size: 16px;">{{ item.all_lost_num }}</span>场</div>
                            </el-col>
                        </el-col>
                    </el-row>
                </el-descriptions-item>
            </el-descriptions>
            <div slot="footer">
                <el-button plain @click="$emit('ExitReportDialog')">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { UserWeeklyRequest,UserYearlyRequest,UserYearlyPartnerRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            type: 'week',
            WeeklyData: {
                role_win_arr:{},
                role_lose_arr:{},
                my_all_num:0,
                my_win_num:0,
                my_lucky_role_arr:{},
                my_win_partner:{},
                my_lose_partner:{},
                my_xiajiucai_partner:{},
                my_kexing_partner:{}
            },
            YearlyData: {
                count_play_num: '',
                count_play_win_num: '',
                count_play_win_ratio: '',
                count_play_jun_num: '',
                count_play_jun_win_num: '',
                count_play_jun_win_ratio: '',
                count_play_qian_num: '',
                count_play_qian_win_num:'',
                count_play_qian_win_ratio:'',
                count_play_jiang_num: '',
                count_play_jiang_win_num:'',
                count_play_jiang_win_ratio:'',
                count_play_shenfen_num: '',
                count_play_shenfen_win_num:'',
                count_play_shenfen_win_ratio:'',
                count_play_sansan_num: '',
                count_play_sansan_win_num: '',
                count_play_sansan_win_ratio: '',
                count_play_jiangyou_num: '',
                count_play_jiangyou_win_num: '',
                count_play_jiangyou_win_ratio: '',
                day_play_last_time:{
                    day:'',
                    time:'',
                    shihou: '',
                    win_lose:''
                }
            },
            YearlyPartner: []
        }
    },
    computed:{},
    methods:{
        get_user_weekly_data() {
            UserWeeklyRequest()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.WeeklyData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_user_yearly_data() {
            UserYearlyRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.YearlyData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_user_yearly_partner_data() {
            UserYearlyPartnerRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.YearlyPartner = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
    filters:{},
    props:{
        ReportDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created(){
        this.get_user_weekly_data()
        this.get_user_yearly_data()
        this.get_user_yearly_partner_data()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>