import { render, staticRenderFns } from "./MySkinDialogComponent.vue?vue&type=template&id=325df414&scoped=true"
import script from "./MySkinDialogComponent.vue?vue&type=script&lang=js"
export * from "./MySkinDialogComponent.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "325df414",
  null
  
)

export default component.exports