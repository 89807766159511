<template>
    <el-dialog
        title="奖券核销"
        :visible.sync="CdKeyTextDialogVisible"
        width="50%"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => CloseCdKeyTextDialog()"
    >
        <el-form
            :model="form"
            ref="CdKeyTextForm"
            label-width="100px"
        >
            <el-descriptions
                class="margin-top"
                :column="1"
                :labelStyle="{'width':'100px','text-align':'center'}"
                :contentStyle="{'text-align': 'center'}"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>奖券抽奖码</span>
                    </template>
                    <el-input v-model="form.key"></el-input>
                </el-descriptions-item>
            </el-descriptions>
        </el-form>
        <div slot="footer">
            <el-button type="primary" plain @click="SubmitCdKeyText()">确 定</el-button>
            <el-button plain @click="CloseCdKeyTextDialog()">取 消</el-button>
        </div>
    </el-dialog>
</template>

<script>
export default {
    name: 'CommonCdKeyTextComnent',
    data() {
        return {
            form: {
                key: ''
            }
        }
    },
    computed: {},
    methods: {
        CloseCdKeyTextDialog() {
            this.form.key = ''
            this.$emit('ExitCdKeyTextDialog')
        },
        SubmitCdKeyText() {
            if (this.form.key === '') {
                this.$message.error('请填写抽奖码')
                return
            }

            this.$emit('PostCdKey',this.form.key)
            this.CloseCdKeyTextDialog()
        },
    },
    filters: {},
    props: {
        CdKeyTextDialogVisible: Boolean,
    },
    created() {},
    destoryed() {},
    mounted() {},
    updated() {},
    components: {},
}
</script>

<style lang='less'></style>