import request from '../request'

//个人对局列表
export const GameRecordMyIndexRequest = p => {
    return request({
        method:'GET',
        url:'QueryGameRecord/MyIndex',
        params: p
    })
}

//全体对局列表
export const GameRecordAllIndexRequest = p => {
    return request({
        method:'GET',
        url:'QueryGameRecord/AllIndex',
        params: p
    })
}

//对局详情
export const GameRecordDetailsRequest = p => {
    return request({
        method:'GET',
        url:'QueryGameRecord/Details',
        params: p
    })
}

//风神榜
export const GameRecordRankingListIndexRequest = p => {
    return request({
        method:'GET',
        url:'QueryGameRecord/RankingList',
        params: p
    })
}