import request from '../request'
//获取抽奖概率
export const CdKeyChanceDetailsRequest = () => {
    return request({
        method:'GET',
        url:'CdKeyChance/details'
    })
}

//修改抽奖概率
export const CdKeyChanceAddRequest = data => {
    return request({
        method:'POST',
        url:'CdKeyChance/add',
        data
    })
}