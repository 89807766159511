<template>
    <el-dialog
        title="荣誉馆"
        :visible.sync="HonorDialogVisible"
        width="800px"
        top="50px"
        :center="true"
        :append-to-body="true"
        :close-on-click-modal="false"
        :before-close="() => CloseHonorDialog()"
    >
        <div style="height:500px;overflow-y:auto">
            <div style="font-family: HarmonyOS_Sans_Light;font-weight: bold;font-size: 20px;text-align: center;margin-bottom: 10px;">
                <span style="color: #B15BFF;">当前拥有荣誉积分：</span><span style="font-family: HarmonyOS_Sans_Black;color: #B15BFF;margin-right: 10px;">{{ TotalScore }}</span>
            </div>
            <el-descriptions
                direction="vertical"
                :column="3"
                :labelStyle="{'text-align':'center','background-color':'#FFFFFF','width':'33.3%','height':'130px'}"
                :contentStyle="{'text-align': 'center','background-color':'#FAF4FF','height':'95px'}"
                border
            >
                <el-descriptions-item v-for="item in ScorePrize" :key="item.name">
                    <template slot="label">
                        <img :src="item.url" height="100px" alt="" style="border-radius: 10px;">
                    </template> 
                    <div style="font-family: HarmonyOS_Sans_Black;font-size: 14px;">
                        <div style="color:#2828FF;">{{ item.name }}</div>
                        <div>所需积分：{{ item.score }} <el-tag type="warning" effect="plain" size="mini" v-if="item.state === 2">限时兑换</el-tag></div>
                        <el-button v-if="TotalScore<item.score" size="mini" plain disabled>积分不足</el-button>
                        <el-button v-else size="mini" plain @click="exchange(item)">兑 换</el-button>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item v-for="i in res_buchong" :key="i+'bc'">
                    <template slot="label">
                        <span></span>
                    </template>
                    <div style="width: 100%"></div>
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <div slot="footer">
            <el-button plain @click="CloseHonorDialog()">改天再来</el-button>
        </div>
        <exchange-dialog-component
            :dialogExchangeVisible="dialogExchangeVisible"
            :id="article_id"
            :max_num="max_num"
            @RefreshTotalScore="$emit('RefreshTotalScore')"
            @ExitExchangeDialog="dialogExchangeVisible = false"
        ></exchange-dialog-component>
    </el-dialog>
</template>

<script>
import { ScoreArticleRequest } from '@/network/user.js'

import ExchangeDialogComponent from '@/components/index/ExchangeDialogComponent'

export default {
    name: 'HonorDialogComponent',
    data() {
        return {
            res_buchong: 0,
            ScorePrize: [],
            dialogExchangeVisible: false,
            article_id: 0,
            max_num: 1
        }
    },
    computed: {},
    methods: {
        CloseHonorDialog() {
            this.$emit('ExitHonorDialog')
        },
        get_score_article_index() {
            ScoreArticleRequest()
                .then((s) => {
                    this.ScorePrize = s.result

                    if(s.result.length  < 7)
                    {
                        this.res_buchong = 6 - s.result.length;
                    }
                    else
                    {
                        const yushu = s.result.length % 3 === 0 ? 3 : s.result.length % 3
                        this.res_buchong = 3 - yushu
                    }

                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err)
                })
        },
        exchange(item) {
            this.article_id = item.id
            this.max_num = Math.floor(this.TotalScore/item.score)
            this.dialogExchangeVisible = true
        }
    },
    filters: {},
    props: {
        HonorDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        TotalScore: {
            type: Number,
            default() {
                return 0
            }
        }
    },
    created() {
        this.get_score_article_index()
    },
    destoryed() {},
    mounted() {},
    updated() {},
    components: {
        ExchangeDialogComponent
    },
}
</script>