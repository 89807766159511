<template>
    <div>
        <el-dialog
            title="抽奖概率公示"
            :visible.sync="ChanceDialogVisible"
            :append-to-body="true"
            :center="true"
            top="20px"
            width="1000px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitChanceDialog')"
        >
            <div style="text-align: center;" v-if="$_has('修改抽奖概率')">
                <img v-if="ChangePicture.length" :src="ChangePicture[0].url" alt="" width="100%">
                <common-upload-component
                    ref="upload"
                    :limit=1
                    :ffileList="ChangePicture"
                ></common-upload-component>
            </div>
            <div v-else style="text-align: center;">
                <img v-if="ChangePicture.length" :src="ChangePicture[0].url" alt="" width="100%">
                <common-upload-component
                    ref="upload"
                    v-show="false"
                    :disabled="true"
                    :ffileList="ChangePicture"
                ></common-upload-component>
            </div>
            
            <div slot="footer">
                <el-button type="primary" plain @click="edit_chance" v-if="$_has('修改抽奖概率')">修改概率</el-button>
                <el-button plain @click="$emit('ExitChanceDialog')">我知道了</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { CdKeyChanceDetailsRequest,CdKeyChanceAddRequest } from '@/network/query/CdKeyChance.js'

import CommonUploadComponent from '@/components/common/CommonUploadComponent'

export default {
    name:'',
    data(){
        return {
            ChangePicture: []
        }
    },
    computed:{},
    methods:{
        get_cd_key_chance_details() {
            CdKeyChanceDetailsRequest()
                .then((s) => {
                    if (s.status === 0) {
                        if(s.result.length > 0) {
                            this.ChangePicture = s.result[0].picture
                        }
                        
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
                .finally()
        },
        edit_chance() {
            this.ChangePicture = this.$refs.upload.fileList
            if(this.ChangePicture.length === 0 ) {
                this.$message.error('请先上传图片')
                return
            }
            
            CdKeyChanceAddRequest({picture: this.ChangePicture})
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.get_cd_key_chance_details()
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
                .finally()
        }
    },
    filters:{},
    props:{
        ChanceDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created(){
        this.get_cd_key_chance_details()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        CommonUploadComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
</style>