<template>
    <div>
        <el-dialog
            title="任务馆"
            :visible.sync="TaskDialogVisible"
            :append-to-body="true"
            width="900px"
            :center="true"
            top="50px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitTaskDialog')"
        >
            <div style="text-align: center;margin-bottom: 5px;">
                <div style="font-family: HarmonyOS_Sans_Light;font-weight: bold;font-size: 18px;">
                    <span style="color: #2828FF;">当前拥有荣誉积分：</span><span style="color: #FF0000;margin-right: 10px;">{{ TotalScore }}</span>
                </div>
            </div>
            <template>
                <el-tabs v-model="activeName" type="border-card" :stretch="true">
                    <el-tab-pane label="常规任务" name="changgui">
                        <div style="font-size: 12px;margin-top: 5px;margin-bottom: 10px;text-align: center;">【特殊说明：所有常规任务仅限5-8人对局】</div>
                        <el-table
                            :data="data"
                            style="width: 100%"
                            border
                            stripe
                            highlight-current-row
                            size="mini"
                        >
                            <el-table-column
                                align="center"
                                label="类型"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <el-tag size="small" effect="dark" type="success" v-if="scope.row.type === '每日任务'">每日任务</el-tag>
                                    <el-tag size="small" effect="dark" v-else-if="scope.row.type === '每周任务'">每周任务</el-tag>
                                    <el-tag size="small" effect="dark" type="success" v-else-if="scope.row.type === '签到任务'">签到任务</el-tag>
                                    <el-tag size="small" effect="dark" type="danger" v-else-if="scope.row.type === '限时活动'">限时活动</el-tag>
                                    <el-tag size="small" effect="dark" type="warning" v-else-if="scope.row.type === '情人节'">情人节</el-tag>
                                    <el-tag size="small" effect="dark" type="info" v-else-if="scope.row.type === '隐藏任务'">隐藏任务</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="state"
                                label="状态"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.state === '未完成'" size="mini" type="info">{{ scope.row.state }}</el-tag>
                                    <el-tag v-else-if="scope.row.state === '已完成'" size="mini" type="success">{{ scope.row.state }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="rank"
                                label="难度"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <el-tag v-if="scope.row.rank === '简单'" size="mini" effect="plain">{{ scope.row.rank }}</el-tag>
                                    <el-tag v-else-if="scope.row.rank === '普通'" size="mini" effect="plain" type="warning">{{ scope.row.rank }}</el-tag>
                                    <el-tag v-else-if="scope.row.rank === '困难'" size="mini" effect="plain" type="danger">{{ scope.row.rank }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="content"
                                label="内容"
                                min-width="180px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.content }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="pace"
                                label="进度"
                                min-width="60px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.pace }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="score"
                                label="分值"
                                min-width="50px"
                            >
                                <template slot-scope="scope">
                                    <span style="color: #8600FF;">{{ scope.row.score }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                fixed="right"
                                label="操作"
                                align="center"
                                min-width="50px"
                            >
                                <template slot-scope="scope">
                                    <el-button
                                        v-if="((scope.row.type==='每日任务') && (scope.row.state==='未完成') && (RefreshDayTask>0)) || ((scope.row.type==='每周任务') && (scope.row.state==='未完成') && (RefreshWeekTask>0))"
                                        plain
                                        size="mini"
                                        @click.native="$emit('RefreshTask',scope.row.id)"
                                    >更换</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                    <el-tab-pane label="等级任务" name="dengji">
                        <el-table
                            :data="RankData"
                            style="width: 100%"
                            border
                            stripe
                            highlight-current-row
                            size="mini"
                        >
                            <el-table-column
                                align="center"
                                label="等级"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.rank ? scope.row.rank + "级" : '' }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="reward"
                                label="奖励"
                                min-width="180px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.reward}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="state"
                                label="状态"
                                min-width="70px"
                            >
                                <template slot-scope="scope">
                                    <div v-if="scope.row.state === 0">
                                        <el-tag size="mini" type="info">未达标</el-tag>
                                    </div>
                                    <div v-else-if="scope.row.state === 1">
                                        <el-button type="primary" size="mini" plain @click="gain(scope.row.rank)">领取</el-button>
                                    </div>
                                    <div v-else-if="scope.row.state === 2">
                                        <el-tag size="mini" type="success">已领取</el-tag>
                                    </div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-tab-pane>
                </el-tabs>
            </template>
            <div slot="footer">
                <el-button plain @click="$emit('ExitTaskDialog')">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { RankTaskGainRequest } from '@/network/query/task.js'

export default {
    name:'',
    data(){
        return {
            activeName: "changgui"
        }
    },
    computed:{},
    methods:{
        gain(rank) {
            RankTaskGainRequest(rank)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('领取成功!')
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                    .finally(()=>this.$emit('RefreshTotalScore'))
        }
    },
    filters:{},
    props:{
        TaskDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        data: {
            type: Array,
            default() {
                return []
            }
        },
        TotalScore: {
            type:Number,
            default: 0
        },
        RefreshDayTask: {
            type:Number,
            default: 0
        },
        RefreshWeekTask: {
            type:Number,
            default: 0
        },
        RankData: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>