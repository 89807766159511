<template>
    <div v-loading.fullscreen.lock="loading">
        <el-card class="home_view">
            <!-- 个人资料板块 -->
            <el-row>
                <!-- 头像 -->
                <el-col :span="4">
                    <div style="position: relative;">
                        <img v-if="UserData.box_picture" :src="UserData.box_picture" height="200px" width="150px" alt="" style="position:absolute;top:0;">
                        <img :src="UserData.dt_picture" height="200px" width="150px" alt="" v-if="UserData.dt_picture">
                        <img :src="UserData.picture" height="200px" width="150px" alt="" v-else>
                    </div>
                </el-col>
                <!-- 个人信息 -->
                <el-col :span="8">
                    <el-row style="height: 160px;">
                        <el-descriptions
                            direction="vertical"
                            :column="1"
                            :labelStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                            :contentStyle="{'text-align': 'center','background-color':'#FFFFFF','font-size':'30px','padding-bottom':'0px'}"
                            :colon="false"
                        >
                            <el-descriptions-item :contentStyle="{'font-size':'18px','text-align': 'center','color':'#9F35FF','font-weight':'bold','white-space':'pre-line'}">
                                <template slot="label">
                                    <div class="font_hm" style="font-size: 30px;color: #2894FF;text-align: center;" v-if="UserData.gender === 1">
                                        <img :src="$store.state.baseURL+'icon/xingbienan.png'" height="25px">
                                        {{UserData.name}}
                                    </div>
                                    <div class="font_hm" style="font-size: 30px;color: #FF60AF;text-align: center;" v-else-if="UserData.gender === 2">
                                        <img :src="$store.state.baseURL+'icon/xingbienv.png'" height="25px">
                                        {{UserData.name}}
                                    </div>
                                </template>
                                <div>{{ UserData.maker }}</div>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-row>
                    <el-row style="text-align: center;">
                        <el-button plain type="primary" @click="MakerDialogVisible = true">修改签名</el-button>
                        <el-button plain type="warning" @click="PwdDialogVisible = true">修改密码</el-button>
                        <password-dialog-component
                            :PwdDialogVisible="PwdDialogVisible"
                            @ExitPwdDialog="PwdDialogVisible = false"
                        ></password-dialog-component>
                    </el-row>
                </el-col>
                <!-- 功能 -->
                <el-col :span="12">
                    <el-descriptions
                        direction="vertical"
                        :column="5"
                        :labelStyle="{'text-align':'center','background-color':'#FFFFFF','padding-bottom':'0px'}"
                        :contentStyle="{'text-align': 'center','background-color':'#FFFFFF','padding-bottom':'0px'}"
                        :colon="false"
                    >
                        <el-descriptions-item>
                            <template slot="label">
                                <div  @click="MyInfoDialogVisible = true">
                                    <img :src="$store.state.baseURL+'icon/dangannan.png'" height="60px" v-if="UserData.gender === 1" class="gongnengtubiao">
                                    <img :src="$store.state.baseURL+'icon/dangannv.png'" height="60px" v-if="UserData.gender === 2" class="gongnengtubiao">
                                </div>
                            </template>
                            <div class="font_hm">我的档案</div>
                            <my-info-dialog-component
                                :MyInfoDialogVisible="MyInfoDialogVisible"
                                :data="UserData"
                                @ExitMyInfoDialog="MyInfoDialogVisible = false"
                            ></my-info-dialog-component>
                            <maker-dialog-component
                                :MakerDialogVisible="MakerDialogVisible"
                                :OldMaker="UserData.maker"
                                @EditMaker="EditMaker"
                                @ExitMakerDialog="MakerDialogVisible = false"
                            ></maker-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="MyItemDialogVisible = true"><img :src="$store.state.baseURL+'icon/gerenwupin.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">我的物品</div>
                            <my-item-dialog-component
                                :MyItemDialogVisible="MyItemDialogVisible"
                                :data="UserData.items"
                                :TotalScore="TotalScore"
                                @Refresh="RefreshTotalScore"
                                @ExitMyItemDialog="MyItemDialogVisible = false"
                            ></my-item-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="MySkinDialogVisible = true"><img :src="$store.state.baseURL+'icon/pifu.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">我的皮肤</div>
                            <my-skin-dialog-component
                                :MySkinDialogVisible="MySkinDialogVisible"
                                :jt_data="UserData.skins"
                                :dt_data="UserData.dynamicbag"
                                @Refresh="RefreshTotalScore"
                                @ExitMySkinDialog="MySkinDialogVisible = false"
                            ></my-skin-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="MyFrameDialogVisible = true"><img :src="$store.state.baseURL+'icon/wodezhuangban.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">我的装扮</div>
                            <my-frame-dialog-component
                                :MyFrameDialogVisible="MyFrameDialogVisible"
                                :bk_data="UserData.framebag"
                                :bjq_data="UserData.backbag"
                                :nck_data="UserData.nickframebag"
                                @Refresh="RefreshTotalScore"
                                @ExitMyFrameDialog="MyFrameDialogVisible = false"
                            ></my-frame-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="MyCdKeyDialogVisible = true"><img :src="$store.state.baseURL+'icon/choujiangquan.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">我的奖券</div>
                            <my-cd-key-dialog-component
                                :MyCdKeyDialogVisible="MyCdKeyDialogVisible"
                                :data="UserBindKey"
                                @RefreshBindKey="get_user_bind_key()"
                                @ExitMyCdKeyDialog="MyCdKeyDialogVisible = false"
                            ></my-cd-key-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="click_task"><img :src="$store.state.baseURL+'icon/renwu.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">任务栏</div>
                            <task-dialog-component
                                :TaskDialogVisible="TaskDialogVisible"
                                :data="TaskData"
                                :RankData=RankData
                                :TotalScore="TotalScore"
                                :RefreshDayTask="RefreshDayTask"
                                :RefreshWeekTask="RefreshWeekTask"
                                @RefreshTask="RefreshTask"
                                @RefreshTotalScore="RefreshTotalScore"
                                @ExitTaskDialog="TaskDialogVisible = false"
                            ></task-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="HonorDialogVisible = true"><img :src="$store.state.baseURL+'icon/liansheng.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">荣誉馆</div>
                            <honor-dialog-component
                                :HonorDialogVisible="HonorDialogVisible"
                                :TotalScore="TotalScore"
                                @RefreshTotalScore="RefreshTotalScore"
                                @ExitHonorDialog="HonorDialogVisible = false"
                            ></honor-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="clk_spl"><img :src="$store.state.baseURL+'icon/duizhanchangshu.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">竞技场</div>
                            <spl-dialog-component
                                :SplDialogVisible="SplDialogVisible"
                                :SplData="SplData"
                                @refresh_spl="get_spl_today()"
                                @ExitSplDialog="ExitSplDialog"
                            ></spl-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <div @click="ReportDialogVisible = true"><img :src="$store.state.baseURL+'icon/zhanbao.png'" height="60px" class="gongnengtubiao"></div>
                            </template>
                            <div class="font_hm">情报局</div>
                            <report-dialog-component
                                :ReportDialogVisible="ReportDialogVisible"
                                :data="UserData.skins"
                                @ExitReportDialog="ReportDialogVisible = false"
                            ></report-dialog-component>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <el-badge v-if="notice_num" :value="notice_num" :max="99" class="home_view">
                                    <div @click="$router.push('/ForumIndex')"><img :src="$store.state.baseURL+'icon/fengshengba.png'" height="60px" class="gongnengtubiao"></div>
                                </el-badge>
                                <div v-else @click="$router.push('/ForumIndex')"><img :src="$store.state.baseURL+'icon/fengshengba.png'" height="60px" class="gongnengtubiao"></div>
                            
                            </template>
                            <div class="font_hm">风声吧</div>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-col>
            </el-row>
            <el-descriptions
                direction="vertical"
                :column="2"
                :labelStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                :contentStyle="{'text-align': 'center','background-color':'#FFFFFF'}"
                :colon="false"
            >
                <!-- 荣誉榜 -->
                <el-descriptions-item>
                    <template slot="label">
                        <div></div>
                    </template>
                    <el-card class="box_card" style="height: 470px;">
                        <el-table
                            :data="ScoreTableData"
                            style="width: 100%"
                            border
                            stripe
                            highlight-current-row
                            size="mini"
                            v-loading="ScoreTableLoading"
                        >
                            <el-table-column>
                                <template v-slot:header>
                                    <div style="text-align: center;">
                                        <span style="font-size: 20px;font-family: HarmonyOS_Sans_Black;color: #CE0000;">荣誉榜</span>
                                        <div style="display: inline;float: right;">
                                            <el-radio-group v-model="ScoreTableCond.type" size="mini" @input="type_change">
                                                <el-radio-button label="score">积分榜</el-radio-button>
                                                <el-radio-button label="spl">竞技榜</el-radio-button>
                                                <el-radio-button label="active">活跃榜</el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </template>
                                <el-table-column
                                    align="center"
                                    type="index"
                                    min-width="10px">
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="name"
                                    label="玩家昵称"
                                    min-width="150px"
                                >
                                    <template slot-scope="scope">
                                        <span>
                                            <img v-if="scope['$index'] === 0" :src="$store.state.baseURL+'icon/jinpai.png'" height="20px">
                                            <img v-else-if="scope['$index'] === 1" :src="$store.state.baseURL+'icon/yinpai.png'" height="20px">
                                            <img v-else-if="scope['$index'] === 2" :src="$store.state.baseURL+'icon/tongpai.png'" height="20px">
                                        </span>
                                    <span>{{ scope.row.name }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="num"
                                    min-width="60px"
                                >
                                    <template v-slot:header>
                                        <span v-if="ScoreTableCond.type === 'score'">任务数量</span>
                                        <span v-else-if="ScoreTableCond.type === 'spl'">夺冠次数</span>
                                        <span v-else-if="ScoreTableCond.type === 'active'">统计时间</span>
                                    </template>
                                    <template slot-scope="scope">
                                        <span v-if="ScoreTableCond.type === 'score'">{{ scope.row.num }}</span>
                                        <span v-else-if="ScoreTableCond.type === 'spl'" style="color: #CE0000;">{{ scope.row.mvps }}</span>
                                        <span v-else-if="ScoreTableCond.type === 'active'">本月</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="score"
                                    min-width="80px"
                                >
                                    <template v-slot:header>
                                        <span v-if="ScoreTableCond.type === 'score'">当前积分</span>
                                        <span v-else-if="ScoreTableCond.type === 'spl'">幸运次数</span>
                                        <span v-else-if="ScoreTableCond.type === 'active'">游戏活跃度</span>
                                    </template>
                                    <template slot-scope="scope">
                                        <span v-if="ScoreTableCond.type === 'score'" style="color: #CE0000;">{{ scope.row.score }}</span>
                                        <span v-else-if="ScoreTableCond.type === 'spl'" style="color: #9F35FF;">{{ scope.row.luckys }}</span>
                                        <span v-else-if="ScoreTableCond.type === 'active'" style="color: #00BB00;">{{ scope.row.active }}</span>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-card>
                </el-descriptions-item>
                <!-- 风神榜 -->
                <el-descriptions-item>
                    <template slot="label">
                        <div></div>
                    </template>
                    <el-card class="box_card" style="height: 470px;">
                        <el-table
                            :data="WinTableData"
                            style="width: 100%"
                            border
                            stripe
                            highlight-current-row
                            size="mini"
                            v-loading="WinTableLoading"
                        >
                            <el-table-column>
                                <template v-slot:header>
                                    <div style="text-align: center;">
                                        <el-tooltip placement="top" effect="light">
                                            <template v-slot:content>
                                                <div style="color: #CE0000;font-weight: bold;">本榜单只统计5-8人局</div>
                                                <div>【本周】本周截止目前数据，周日晚上更新到周榜</div>
                                                <div>【周榜】上周统计</div>
                                                <div>【月榜】上月统计</div>
                                                <div>【总榜】历史总计</div>
                                            </template>
                                            <span style="font-size: 20px;font-family: HarmonyOS_Sans_Black;color: #CE0000;">风神榜</span>
                                        </el-tooltip>
                                        <div style="display: inline;float: right;">
                                            <el-radio-group v-model="WinTableCond.time" size="mini" @input="time_change">
                                                <el-radio-button label="current_week">本周</el-radio-button>
                                                <el-radio-button label="last_week">周榜</el-radio-button>
                                                <el-radio-button label="last_month">月榜</el-radio-button>
                                                <el-radio-button label="all">总榜</el-radio-button>
                                            </el-radio-group>
                                        </div>
                                    </div>
                                </template>
                                <el-table-column
                                    align="center"
                                    type="index"
                                    min-width="10px">
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="nick"
                                    label="玩家昵称"
                                    min-width="150px"
                                >
                                    <template slot-scope="scope">
                                        <span>
                                            <img v-if="scope['$index'] === 0" :src="$store.state.baseURL+'icon/jinpai.png'" height="20px">
                                            <img v-else-if="scope['$index'] === 1" :src="$store.state.baseURL+'icon/yinpai.png'" height="20px">
                                            <img v-else-if="scope['$index'] === 2" :src="$store.state.baseURL+'icon/tongpai.png'" height="20px">
                                        </span>
                                    <span>{{ scope.row.nick }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="wintimes"
                                    label="胜场"
                                    min-width="50px"
                                >
                                    <template slot-scope="scope">
                                        <span style="color: #CE0000;">{{ scope.row.wintimes }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    align="center"
                                    prop="winrate"
                                    label="胜率"
                                    min-width="70px"
                                >
                                    <template slot-scope="scope">
                                        <span style="color: #921AFF;">{{ scope.row.winrate+'%' }}</span>
                                    </template>
                                </el-table-column>
                            </el-table-column>
                        </el-table>
                    </el-card>
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
    </div>
</template>

<script>
import { UserBindKeyRequest,MyInformationRequest } from '@/network/user.js'
import { GameRecordRankingListIndexRequest } from '@/network/query/GameRecord.js'
import { UserTaskQueryRequest,UserTaskCountRequest,RankTaskQueryRequest,UserTaskRefreshRequest } from '@/network/query/task.js'
import { UserScoreListRequest,UserSplListRequest,UserActiveListRequest } from '@/network/query/score.js'
import { SplTodayRequest } from '@/network/query/spl.js'
import { ForumNoticeNumRequest } from '@/network/forum.js'

import PasswordDialogComponent from '@/components/index/PasswordDialogComponent'
import MakerDialogComponent from '@/components/index/MakerDialogComponent'
import MyInfoDialogComponent from '@/components/index/MyInfoDialogComponent'
import MyItemDialogComponent from '@/components/index/MyItemDialogComponent'
import MySkinDialogComponent from '@/components/index/MySkinDialogComponent'
import MyFrameDialogComponent from '@/components/index/MyFrameDialogComponent'
import MyCdKeyDialogComponent from '@/components/index/MyCdKeyDialogComponent'
import TaskDialogComponent from '@/components/index/TaskDialogComponent'
import HonorDialogComponent from '@/components/index/HonorDialogComponent'
import SplDialogComponent from '@/components/index/SplDialogComponent'
import ReportDialogComponent from '@/components/index/ReportDialogComponent'

export default {
    name:'HomeView',
    data(){
        return {
            loading: false,
            UserBindKey: [],
            UserData: {
                maker: '',
                skins: [],
                items: [],
                framebag: [],
                backbag: [],
            },
            WinTableData: [],
            WinTableLoading: false,
            WinTableCond: {
                time: 'current_week'
            },
            ScoreTableData: [],
            ScoreTableLoading: false,
            ScoreTableCond: {
                type: 'score'
            },
            SplData: {},
            TaskData:[],    //常规任务
            RankData:[],    //等级任务
            TotalScore: 0,   //总荣誉积分
            RefreshDayTask: 0,  //日任务刷新次数
            RefreshWeekTask: 0, //周任务刷新次数
            notice_num: 0,  //贴吧通知
            PwdDialogVisible: false,
            MakerDialogVisible: false,
            MyInfoDialogVisible:false,
            MyItemDialogVisible:false,
            MySkinDialogVisible:false,
            MyFrameDialogVisible:false,
            MyBackDialogVisible:false,
            MyCdKeyDialogVisible: false,
            TaskDialogVisible:false,
            HonorDialogVisible:false,
            SplDialogVisible: false,
            ReportDialogVisible:false
        }
    },
    computed:{},
    methods:{
        get_user_bind_key() {
            UserBindKeyRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.UserBindKey = s.result.data
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_my_information() {
            this.loading = true
            MyInformationRequest()
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.UserData = s.result
                        this.$store.commit('edit_user', s.result)

                        this.RefreshDayTask = s.result.refresh_day_task
                        this.RefreshWeekTask = s.result.refresh_week_task
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.get_user_task_count())
        },
        get_game_record_ranking_list() {
            this.WinTableLoading = true
            GameRecordRankingListIndexRequest(this.WinTableCond)
                .then((s) => {
                    this.loading = false
                    if (s.status === 0) {
                        this.WinTableData = s.result.data
                    } else {
                        this.$message.error(s.msg)
                        
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.WinTableLoading = false)
        },
        time_change(v) {
            this.WinTableCond.time = v
            this.get_game_record_ranking_list()
        },
        EditMaker(v) {
            this.UserData.maker = v
        },
        click_task() {
            this.TaskDialogVisible = true
            this.get_user_task_count()
        },
        get_user_task_query() {
            UserTaskQueryRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.TotalScore = s.result.score
                        this.TaskData = s.result.task
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.get_rank_task_query())
        },
        get_rank_task_query() {
            RankTaskQueryRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.RankData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        get_user_task_count() {
            const with_player_id = this.$store.state.user.with_player_id
            UserTaskCountRequest(with_player_id)
                .then((s) => {
                    if (s.status === 0) {
                        //只需要等响应后再请求查询接口刷新数据即可
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.get_user_task_query())
        },
        RefreshTotalScore() {
            this.get_user_task_query()
            this.get_user_bind_key()
            this.get_my_information()
        },
        get_user_score_list() {
            this.ScoreTableLoading = true
            UserScoreListRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.ScoreTableData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.ScoreTableLoading = false)
        },
        get_user_spl_list() {
            this.ScoreTableLoading = true
            UserSplListRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.ScoreTableData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.ScoreTableLoading = false)
        },
        get_user_active_list() {
            this.ScoreTableLoading = true
            UserActiveListRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.ScoreTableData = s.result
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(()=>this.ScoreTableLoading = false)
        },
        type_change(v) {
            this.ScoreTableCond.type = v
            if(v === 'score') {
                this.get_user_score_list()
            } else if(v === 'spl') {
                this.get_user_spl_list()
            } else if(v === 'active') {
                this.get_user_active_list()
            }
            
        },
        get_spl_today() {
            SplTodayRequest()
                .then((s) => {
                    this.SplData = s
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        clk_spl() {
            if(this.SplData.status !== 0) {
                this.$message.warning(this.SplData.msg)
                return
            }
            this.SplDialogVisible = true
        },
        ExitSplDialog() {
            this.SplDialogVisible = false
            this.get_spl_today()
        },
        RefreshTask(user_task_id) {
            const task_id=user_task_id
            this.$confirm(
                '是否确定刷新任务？',
                '温馨提示',
                {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                }
            )
                .then(() => {
                    UserTaskRefreshRequest(task_id)
                        .then((s) => {
                            if (s.status === 0) {
                                this.$message.success('任务已刷新!')
                            } else {
                                this.$message.error(s.msg)
                            }
                        })
                        .catch((err) => {
                            console.log(err)
                        })
                        .finally(()=>this.RefreshTotalScore())
                })
                .catch((err) => {})
        },
        get_notice_num() {
            ForumNoticeNumRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.notice_num = s.result.num
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.$message.error(err)
                })
        },
    },
    filters:{},
    props:{},
    created(){
        this.get_user_bind_key()
        this.get_my_information()
        this.get_user_score_list()
        this.get_game_record_ranking_list()
        this.get_spl_today()
        this.get_notice_num()
    },
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        PasswordDialogComponent,
        MakerDialogComponent,
        MyInfoDialogComponent,
        MyItemDialogComponent,
        MySkinDialogComponent,
        MyFrameDialogComponent,
        MyCdKeyDialogComponent,
        TaskDialogComponent,
        HonorDialogComponent,
        SplDialogComponent,
        ReportDialogComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
.font_hm {
    font-family: HarmonyOS_Sans_Black;
    color: #2828FF;
    margin-bottom: 10px;
}
.gongnengtubiao  {
    cursor: pointer;
    transition: 0.5s;
}
.gongnengtubiao:not(.turned):hover {
    transform: scale(1.3);
    filter: none;
}
</style>
<style>
.home_view .el-badge__content.is-fixed {
    top: 10px;
    right: 20px
}
</style>