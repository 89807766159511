import request from './request'

//贴子列表
export const ForumIndexRequest = p => {
    return request({
        method:'GET',
        url:'forum/index',
        params: p
    })
}

//贴子详情
export const ForumdetailsRequest = id => {
    return request({
        method:'GET',
        url:'forum/details',
        params: {
            id
        }
    })
}

//新增贴子
export const ForumAddRequest = data => {
    return request({
        method:'POST',
        url:'forum/add',
        data: data
    })
}

//修改贴子
export const ForumEditRequest = data => {
    return request({
        method:'PUT',
        url:'forum/edit',
        data: data
    })
}

//回复贴子
export const ForumReplyRequest = data => {
    return request({
        method:'POST',
        url:'forum/reply',
        data: data
    })
}

//回复评论
export const ForumChatRequest = data => {
    return request({
        method:'PUT',
        url:'forum/chat',
        data: data
    })
}

//贴子置顶
export const ForumTopRequest = id => {
    return request({
        method:'PUT',
        url:'forum/top',
        data: {
            id
        }
    })
}

//贴子加精
export const ForumNiceRequest = id => {
    return request({
        method:'PUT',
        url:'forum/nice',
        data: {
            id
        }
    })
}

//删除贴子
export const ForumDelTitleRequest = width_title_id => {
    return request({
        method:'DELETE',
        url:'forum/del_title',
        data: {
            width_title_id
        }
    })
}

//删除回复
export const ForumDelContentRequest = width_content_id => {
    return request({
        method:'DELETE',
        url:'forum/del_content',
        data: {
            width_content_id
        }
    })
}

//点赞评论
export const ForumZanRequest = width_content_id => {
    return request({
        method:'PUT',
        url:'forum/zan',
        data: {
            width_content_id
        }
    })
}

//贴吧通知列表
export const ForumNoticeIndexRequest = p => {
    return request({
        method:'GET',
        url:'forum/notice_index',
        params: p
    })
}

//贴吧未读通知
export const ForumNoticeNumRequest = () => {
    return request({
        method:'GET',
        url:'forum/notice_num',
    })
}

//标记通知已读
export const ForumNoticeReadRequest = notice_id => {
    return request({
        method:'GET',
        url:'forum/notice_read',
        params: {
            notice_id
        }
    })
}

