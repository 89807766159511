<template>
    <div>
        <el-dialog
            title="兑 换"
            :visible.sync="dialogExchangeVisible"
            :append-to-body="true"
            width="400px"
            :center="true"
            top="200px"
            :close-on-click-modal="false"
            :before-close="() => closeExchangeDialog()"
        >
            <el-form
                :model="ExchangeForm"
                :rules="rules"
                ref="ExchangeForm"
                label-width="100px"
            >
                <el-form-item label="兑换数量" prop="num">
                    <el-input-number
                        v-model="ExchangeForm.num"
                        :min="1"
                        :max="max_num"
                        :step-strictly="true"
                    ></el-input-number>
                </el-form-item>
                <div style="text-align: center;color: #FF0000;font-weight: bold;">温馨提示：</div>
                <div style="color: #2828FF;font-weight: bold;">如兑换风币，则次日05:30到账；如兑换其他物品，则立刻到账，可在【个人物品/个人券包】中查看兑对应物品。</div>
                <div></div>
            </el-form>
            <div slot="footer">
                <el-button type="success" plain @click="submit('ExchangeForm')" >兑 换</el-button>
                <el-button plain @click="closeExchangeDialog()">取 消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { ScoreExchangeRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            ExchangeForm: {
                num: 1
            },
            rules: {
                num: [
                    {
                        required: true,
                        message: '请填写兑换数量',
                        trigger: 'blur',
                    }
                ]
            }
        }
    },
    computed:{},
    methods:{
        closeExchangeDialog() {
            this.ExchangeForm = {
                num: 1
            }
            this.$emit('ExitExchangeDialog')
        },
        submit(formName) {
            this.$refs[formName].validate((valid) => {
                if (!valid) {
                    return
                }

                this.ExchangeForm.article_id = this.id
                ScoreExchangeRequest(this.ExchangeForm)
                    .then((s) => {
                        if (s.status === 0) {
                            this.$message.success('兑换成功！')
                            this.$emit('RefreshTotalScore')
                            this.closeExchangeDialog()
                        } else {
                            this.$message.error(s.msg)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
            })
        }
    },
    filters:{},
    props:{
        dialogExchangeVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        id: {
            type: Number,
            default() {
                return 0
            }
        },
        max_num: {
            type: Number,
            default() {
                return 1
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>