<template>
    <div>
        <el-dialog
            title="我的皮肤"
            :visible.sync="MySkinDialogVisible"
            :append-to-body="true"
            width="800px"
            :center="true"
            top="100px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitMySkinDialog')"
            v-loading.fullscreen.lock="login_loading"
        >
            <el-tabs v-model="activeName" type="border-card" :stretch="true">
                <el-tab-pane label="静态皮肤" name="skins">
                    <div style="font-family: HarmonyOS_Sans_Black;font-size: 20px;margin:10px;text-align: center;">您目前拥有 <span style="color: #CE0000">{{ jt_data.length }}</span> 个静态皮肤（双击使用）</div>
                    <el-descriptions
                        direction="vertical"
                        :column="1"
                        :labelStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                        :contentStyle="{'text-align': 'center','background-color':'#FFFFFF'}"
                        :colon="false"
                    >
                        <el-descriptions-item :labelStyle="{'width':'560px'}">
                            <template slot="label">
                                <div></div>
                            </template>
                            <el-carousel :interval="3000" indicator-position="none" type="card" height="300px">
                                <el-carousel-item v-for="item in jt_data" :key="item.name">
                                    <img :src="item.url" height="90%" alt="" style="border-radius: 10px;" @dblclick="use_img('skin',item.name)">
                                    <div>
                                        <span>{{ item.name }}&nbsp;</span>
                                        <el-tag v-if="item.is_current" effect="plain" size="mini">使用中</el-tag>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>
                <el-tab-pane label="动态皮肤" name="dynamicbag">
                    <div style="font-family: HarmonyOS_Sans_Black;font-size: 20px;margin:10px;text-align: center;">您目前拥有 <span style="color: #CE0000">{{ dt_data.length }}</span> 个动态皮肤（双击使用）</div>
                    <el-descriptions
                        direction="vertical"
                        :column="1"
                        :labelStyle="{'text-align':'center','background-color':'#FFFFFF'}"
                        :contentStyle="{'text-align': 'center','background-color':'#FFFFFF'}"
                        :colon="false"
                    >
                        <el-descriptions-item :labelStyle="{'width':'560px'}">
                            <template slot="label">
                                <div></div>
                            </template>
                            <el-carousel :interval="3000" indicator-position="none" type="card" height="300px">
                                <el-carousel-item v-for="item in dt_data" :key="item.name">
                                    <img :src="item.url" height="90%" alt="" style="border-radius: 10px;" @dblclick="use_img('dynamics',item.name)">
                                    <div>
                                        <span>{{ item.name }}&nbsp;</span>
                                        <el-tag v-if="item.is_current" effect="plain" size="mini">使用中</el-tag>
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </el-descriptions-item>
                    </el-descriptions>
                </el-tab-pane>
            </el-tabs>
            <div slot="footer">
                <el-button plain @click="$emit('ExitMySkinDialog')">关闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { EditDefaultRequest } from '@/network/user.js'

export default {
    name:'',
    data(){
        return {
            activeName: 'skins',
            login_loading: false
        }
    },
    computed:{},
    methods:{
        use_img(type,name) {
            this.login_loading = true
            EditDefaultRequest({type:type,name:name})
                .then((s) => {
                    this.login_loading = false
                    if (s.status === 0) {
                        this.$message.success('修改成功!')
                        this.$emit('Refresh')
                    } else {
                        this.$message.error(s.msg)
                    }
                })
                .catch((err) => {
                    this.login_loading = false
                    this.$message.error(err)
                })
        }
    },
    filters:{},
    props:{
        MySkinDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        jt_data: {
            type: Array,
            default() {
                return []
            }
        },
        dt_data: {
            type: Array,
            default() {
                return []
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>