<template>
    <div>
        <el-dialog
            title="赛事规则"
            :visible.sync="RuleDialogVisible"
            :append-to-body="true"
            width="850px"
            :center="true"
            top="20px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitRuleDialog')"
        >
            <el-descriptions
                :column="1"
                :labelStyle="{'width':'110px','text-align':'center'}"
                :contentStyle="{'text-align': 'left'}"
                border
            >
                <el-descriptions-item>
                    <template slot="label">
                        <span>开放日期</span>
                    </template>
                    <div>每周五、周六、周日</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>报名时间</span>
                    </template>
                    <div>开放日当天0点-20点可以报名参加当天晚上的竞技赛</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>报名条件</span>
                    </template>
                    <div>当周有进行过5场游戏</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>报名消耗</span>
                    </template>
                    <div>2荣誉积分</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>竞技时间</span>
                    </template>
                    <div>开放日当天20点-22点</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>冠军奖励</span>
                    </template>
                    <div>奖池内所有荣誉积分</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>幸运奖励</span>
                    </template>
                    <div>1个抽奖券(参赛人数>=10人开启)</div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>奖池说明</span>
                    </template>
                    <div>
                        <div>1、开放当日奖池内有5初始荣誉积分。</div>
                        <div>2、开放当日所有人报名扣除的荣誉积分加入奖池。</div>
                        <div>3、奖励发放后，下个开放日奖池内重置为5初始荣誉积分。</div>
                    </div>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <span>竞技说明</span>
                    </template>
                    <div>
                        <div>1、竞技对局必需为5-8人身份模式。</div>
                        <div>2、竞技时间内，每获胜一场，可获得与对局人数相等的竞技分失败不扣竞技分。</div>
                        <div>3、统计时间以对局结束时间为准，对局结束时间超出22点的对局不做统计。</div>
                        <div>可在开放日20点前参与对局，并在20点后结束对局，也会计算为竞技对局。</div>
                        <div>4、竞技时间结束后，竟技分最多的玩家为本场竞技获胜者，将获得奖池内所有荣誉积分。</div>
                        <div>如有多个玩家同时竞技分最多，则平分奖池内积分，小数点四舍五。</div>
                        <div>5、如当天竞技人数>=10人，则额外开启幸运奖励，将在所有竞技者中随机一个人，发放幸运奖。</div>
                        <div>竞技人数指当天参与过竞技对局的人数，非报名人数。</div>
                        <div>幸运奖中奖几率与竞技积分有关，竟技积分越高，获得几率越高。</div>
                        <div>6、当日奖励结算后，所有玩家竞技积分清0。</div>
                        <div>7、一旦报名，不支持退赛，如当晚未参与竞技，也不会返还扣除的荣誉积分。</div>
                        <div>8、可在网页后台及群机器人进行报名参赛。竞技结束后，网页后台和群机器人会公布结果及获奖人员。</div>
                        <div>9、如发现有玩家多开刷分、开小号送分或恶意挂机等行为，在核实无误后，将会进行封号处理。</div>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
            <div slot="footer">
                <el-button plain @click="$emit('ExitRuleDialog')">关 闭</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
export default {
    name:'',
    data(){
        return {}
    },
    computed:{},
    methods:{},
    filters:{},
    props:{
        RuleDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{},
    watch:{}
}
</script>

<style lang='less' scoped>
</style>