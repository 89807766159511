<template>
    <div>
        <el-dialog
            title="竞技场"
            :visible.sync="SplDialogVisible"
            :append-to-body="true"
            width="1050px"
            :center="true"
            top="50px"
            :close-on-click-modal="false"
            :before-close="() => $emit('ExitSplDialog')"
        >
            <div v-if="SplData.status === 0">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <div style="text-align: center;margin-bottom: 10px;font-family: HarmonyOS_Sans_Black;font-weight: bold;font-size: 25px;">今日赛事</div>
                        <el-descriptions
                            :column="1"
                            :labelStyle="{'width':'110px','text-align':'center'}"
                            :contentStyle="{'text-align': 'center'}"
                            border
                        >
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>赛事编号</span>
                                </template>
                                <span style="color: #2828FF;">{{ SplData.result.mark }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>赛事规则</span>
                                </template>
                                <span style="color: #2828FF;cursor: pointer;" @click="RuleDialogVisible = true">点击查看</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>比赛时间</span>
                                </template>
                                <span>{{ SplData.result.time }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>冠军奖励</span>
                                </template>
                                <span style="color: #FF0000;">{{ SplData.result.mvp_reward }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>幸运奖励</span>
                                </template>
                                <span style="color: #8600FF;">{{ SplData.result.lucky_reward }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>报名人数</span>
                                </template>
                                <span>{{ SplData.result.num+'人' }}</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>报名玩家</span>
                                </template>
                                <span v-if="SplData.result.num" style="color: #2894FF;">{{ SplData.result.names}}</span>
                                <span v-else >（暂无玩家报名）</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>当前状态</span>
                                </template>
                                <el-tag type="info" size="mini" v-if="SplData.result.state === '未开赛'">未开赛</el-tag>
                                <el-tag type="success" size="mini" v-else-if="SplData.result.state === '比赛中'">比赛中</el-tag>
                                <el-tag type="danger" size="mini" v-else-if="SplData.result.state === '已结束'">已结束</el-tag>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>本场冠军</span>
                                </template>
                                <span v-if="SplData.result.mvp_user" style="color: #2894FF;">{{ SplData.result.mvp_user}}</span>
                                <span v-else >（待定）</span>
                            </el-descriptions-item>
                            <el-descriptions-item>
                                <template slot="label">
                                    <span>本场幸运儿</span>
                                </template>
                                <span v-if="SplData.result.lucky_user" style="color: #2894FF;">{{ SplData.result.lucky_user}}</span>
                                <span v-else >（待定）</span>
                            </el-descriptions-item>
                        </el-descriptions>
                    </el-col>
                    <el-col :span="12">
                        <div style="text-align: center;margin-bottom: 10px;font-family: HarmonyOS_Sans_Black;font-weight: bold;font-size: 25px;">实时赛况</div>
                        <el-table
                            :data="SplData.result.score_arr"
                            style="width: 100%"
                            border
                            stripe
                            highlight-current-row
                            height="462px"
                        >
                            <el-table-column
                                align="center"
                                prop="name"
                                label="玩家昵称"
                                min-width="150px"
                            >
                                <template slot-scope="scope">
                                    <span>{{ scope.row.name }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="num"
                                label="胜场"
                                min-width="50px"
                            >
                                <template slot-scope="scope">
                                    <span style="color: #921AFF;">{{ scope.row.num }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                prop="score"
                                label="竞技分"
                                min-width="80px"
                            >
                                <template slot-scope="scope">
                                    <span style="color: #CE0000;">{{ scope.row.score }}</span>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </div>
            <div v-else>{{ SplData.msg }}</div>
            <div slot="footer">
                <el-button type="primary" plain icon="el-icon-refresh" circle @click="$emit('refresh_spl')">刷新</el-button>
                <el-button plain type="success" @click="add_spl" v-if="(SplData.status===0) && (SplData.result.add_button)">报 名</el-button>
                <el-button plain @click="$emit('ExitSplDialog')">关 闭</el-button>
                <rule-dialog-component
                    :RuleDialogVisible="RuleDialogVisible"
                    @ExitRuleDialog="RuleDialogVisible = false"
                ></rule-dialog-component>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import RuleDialogComponent from '@/components/index/RuleDialogComponent'

import { SplAddRequest } from '@/network/query/spl.js'

export default {
    name:'',
    data(){
        return {
            RuleDialogVisible: false
        }
    },
    computed:{},
    methods:{
        add_spl() {
            SplAddRequest()
                .then((s) => {
                    if (s.status === 0) {
                        this.$message.success('报名成功')
                        this.$emit('refresh_spl')
                    } else {
                        this.$message.error(s.msg)
                        
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally()
        }
    },
    filters:{},
    props:{
        SplDialogVisible: {
            type: Boolean,
            default() {
                return false
            }
        },
        SplData: {
            type: Object,
            default() {
                return {}
            }
        }
    },
    created(){},
    beforeDestroy(){},
    mounted(){},
    updated(){},
    components:{
        RuleDialogComponent
    },
    watch:{}
}
</script>

<style lang='less' scoped>
</style>